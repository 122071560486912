<template>
  <div style="padding-bottom: 40px" class="nodehistoryrecord">
    <el-collapse v-model="activeName">
      <div
        class="infinite-list"
        v-infinite-scroll="loadinfinite"
        :infinite-scroll-distance="10"
        style="overflow: auto; height: 500px"
      >
        <el-collapse-item v-for="i in infiniteCount" :key="i" :name="i">
          <!-- v-for="(item, index) in history_records_list"
          :key="index"
          :name="index" -->
          <template slot="title">
            <span style="font-size: 14px; color: rgb(128, 128, 128)">
              <span style="margin-right: 20px">
                {{ history_records_list[i - 1].children[0].nickname }}
              </span>
              <span style="margin-right: 20px">
                {{ history_records_list[i - 1].children[0].createdAt }}
              </span>
              <span
                v-if="getType(history_records_list[i - 1].scene)"
                style="
                  margin-right: 20px;
                  color: #409eff;
                  background: #ecf5ff;
                  border-color: #b3d8f;
                  display: inline-block;
                  border-radius: 4px;
                  line-height: 20px;
                  padding: 6px 10px;
                "
              >
                {{ getType(history_records_list[i - 1].scene) }}
              </span>
            </span>
          </template>
          <el-table
            v-loading="!versionFinish"
            :data="history_records_list[i - 1].children"
            style="width: 95%"
            header-row-class-name="table_header"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column header-align="center">
              <template slot-scope="scope">
                <div class="uesr">
                  <personal-avatar
                    :size="40"
                    style="margin-right: 10px"
                    :nickname="scope.row.nickname"
                    :colorid="scope.row.accountId"
                  ></personal-avatar>
                  <p>{{ scope.row.nickname }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              header-align="center"
              :label="$t('nodeDetail.activity.history.option')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ typeMap[scope.row.type] || scope.row.type }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              header-align="center"
              :label="$t('nodeDetail.activity.history.originalData')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.before !==
                      $t('nodeDetail.activity.history.ms1') &&
                    !scope.row.beforeSrcList &&
                    scope.row.type !== 'Create' &&
                    !scope.row.beforeDescription &&
                    scope.row.itemChName !==
                      $t('nodeDetail.activity.history.teststep')
                  "
                >
                  <span v-if="scope.row.itemName !== 'thirdLink'">
                    {{
                      `${scope.row.itemChName}: ${
                        scope.row.before && scope.row.before.length > 0
                          ? scope.row.before
                          : $t("nodeDetail.activity.history.null")
                      }`
                    }}
                  </span>
                  <span v-else>
                    {{ scope.row.itemChName }}:<a
                      v-if="scope.row.before && scope.row.before.length > 0"
                      href="#"
                      class="check-btn"
                      @click="openTHIRDLINK(scope.row.before)"
                      >{{ $t("permission.node.view") }}</a
                    >
                    <span v-else>
                      {{ $t("nodeDetail.activity.history.null") }}
                    </span>
                  </span>
                </span>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="
                    scope.row.before !==
                      $t('nodeDetail.activity.history.ms1') &&
                    !scope.row.beforeSrcList &&
                    scope.row.type !== 'Create' &&
                    !scope.row.beforeDescription &&
                    scope.row.itemChName ===
                      $t('nodeDetail.activity.history.teststep')
                  "
                >
                  <div>{{ `${scope.row.itemChName}:` }}</div>
                  <div v-if="scope.row.before && scope.row.before.length > 0">
                    <view-test-step :data="scope.row.before"></view-test-step>
                  </div>
                  <div v-else>{{ $t("nodeDetail.activity.history.null") }}</div>
                </div>
                <span v-else></span>
                <span
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span v-if="scope.row.beforeSrcList">
                    <i
                      class="el-icon-refresh-left"
                      style="
                        color: #2f64b5;
                        cursor: pointer;
                        font-size: 20px;
                        font-weight: bold;
                        margin-right: 6px;
                      "
                      @click="recoverDrawio(scope.row)"
                    ></i
                    >{{ scope.row.itemChName }}:</span
                  >
                  <span
                    v-if="
                      scope.row.beforeSrcList &&
                      scope.row.beforeSrcList.length === 0
                    "
                    >{{ $t("nodeDetail.activity.history.null") }}</span
                  >
                  <el-image
                    v-if="
                      scope.row.beforeSrcList &&
                      scope.row.beforeSrcList.length === 1
                    "
                    style="width: 100px; height: 100px"
                    :src="scope.row.beforeSrcList[0]"
                    :preview-src-list="scope.row.beforeSrcList"
                  >
                  </el-image>
                  <span
                    v-if="
                      scope.row.beforeSrcList &&
                      scope.row.beforeSrcList.length > 1
                    "
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.beforeSrcList)"
                    >+{{ scope.row.beforeSrcList.length }}</span
                  >
                </span>
                <span v-if="scope.row.beforeDescription">
                  <span>{{ $t("nodeDetail.description.title") }}:</span>
                  <span v-if="scope.row.beforeDescriptionContent"
                    ><a
                      href="#"
                      class="check-btn"
                      @click="
                        openDescriptionDialog(
                          scope.row.beforeDescriptionContent
                        )
                      "
                      >{{ $t("permission.node.view") }}</a
                    ></span
                  >
                  <span v-else>{{
                    $t("nodeDetail.activity.history.null")
                  }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="after"
              align="center"
              header-align="center"
              :label="$t('nodeDetail.activity.history.UpdatedData')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    scope.row.after !== $t('nodeDetail.activity.history.ms1') &&
                    !scope.row.afterSrcList &&
                    scope.row.type !== 'Create' &&
                    !scope.row.afterDescription &&
                    scope.row.itemChName !==
                      $t('nodeDetail.activity.history.teststep')
                  "
                >
                  <span v-if="scope.row.itemName !== 'thirdLink'">
                    {{
                      `${scope.row.itemChName}: ${
                        scope.row.after && scope.row.after.length > 0
                          ? scope.row.after
                          : $t("nodeDetail.activity.history.null")
                      }`
                    }}
                  </span>
                  <span v-else>
                    {{ scope.row.itemChName }}:<a
                      v-if="scope.row.after && scope.row.after.length > 0"
                      href="#"
                      class="check-btn"
                      @click="openTHIRDLINK(scope.row.after)"
                      >{{ $t("permission.node.view") }}</a
                    >
                    <span v-else>
                      {{ $t("nodeDetail.activity.history.null") }}
                    </span>
                  </span>
                </span>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  v-if="
                    scope.row.after !== $t('nodeDetail.activity.history.ms1') &&
                    !scope.row.afterSrcList &&
                    scope.row.type !== 'Create' &&
                    !scope.row.afterDescription &&
                    scope.row.itemChName ===
                      $t('nodeDetail.activity.history.teststep')
                  "
                >
                  <div>{{ `${scope.row.itemChName}:` }}</div>
                  <div v-if="scope.row.after && scope.row.after.length > 0">
                    <view-test-step :data="scope.row.after"></view-test-step>
                  </div>
                  <div v-else>{{ $t("nodeDetail.activity.history.null") }}</div>
                </div>
                <span v-else></span>
                <span
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span v-if="scope.row.afterSrcList"
                    >{{ scope.row.itemChName }}:</span
                  >
                  <span
                    v-if="
                      scope.row.afterSrcList &&
                      scope.row.afterSrcList.length === 0
                    "
                    >{{ $t("nodeDetail.activity.history.null") }}</span
                  >
                  <el-image
                    v-if="
                      scope.row.afterSrcList &&
                      scope.row.afterSrcList.length === 1
                    "
                    style="width: 100px; height: 100px"
                    :src="scope.row.afterSrcList[0]"
                    :preview-src-list="scope.row.afterSrcList"
                  >
                  </el-image>
                  <span
                    v-if="
                      scope.row.afterSrcList &&
                      scope.row.afterSrcList.length > 1
                    "
                    class="img-viewer-text"
                    @click="showImgViewer(scope.row.afterSrcList)"
                    >+{{ scope.row.afterSrcList.length }}</span
                  >
                </span>

                <span v-if="scope.row.afterDescription">
                  <span>{{ $t("nodeDetail.description.title") }}:</span>
                  <span v-if="scope.row.afterDescriptionContent"
                    ><a
                      href="#"
                      class="check-btn"
                      @click="
                        openAfterDesc(
                          scope.row.beforeDescriptionContent,
                          scope.row.afterDescriptionContent
                        )
                      "
                      >{{ $t("permission.node.view") }}</a
                    ></span
                  >
                  <span v-else>{{
                    $t("nodeDetail.activity.history.null")
                  }}</span>
                </span>
              </template>
            </el-table-column>
            <el-img-viewer
              v-if="isViewerVisible"
              class="viewer"
              :url-list="currentSrcList"
              :on-close="closeImgViewer"
            ></el-img-viewer>
            <el-dialog
              :title="$t('nodeDetail.description.title')"
              :visible.sync="descriptionVisible"
              :show-close="true"
              append-to-body
              width="50%"
            >
              <div class="html-template" v-html="currentDescription"></div>
            </el-dialog>
            <el-dialog
              :title="$t('nodeDetail.third.title')"
              :visible.sync="ThirdlinkVisible"
              :show-close="true"
              append-to-body
              width="80%"
            >
              <el-tabs v-model="Thirdlink_activeName">
                <el-tab-pane
                  :name="String(ind)"
                  v-for="(tabs, ind) in Thirdlink_data"
                  :key="ind"
                  :label="tabs.name"
                >
                  <div style="overflow: auto; width: 100%">
                    <div
                      v-if="Thirdlink_tab_data[ind].link.length == 0"
                      style="
                        margin: 20px auto;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      空
                    </div>
                    <el-carousel
                      indicator-position="outside"
                      :autoplay="false"
                      v-if="
                        Thirdlink_tab_data &&
                        Thirdlink_tab_data[ind] &&
                        Thirdlink_tab_data[ind].link.length > 0
                      "
                    >
                      <el-carousel-item
                        v-for="(item, index) in Thirdlink_tab_data[ind].link"
                        :key="index"
                        style="border: 1px solid #cfcfcf; border-radius: 8px"
                      >
                        <!-- <div class="header">
                          <el-tooltip
                            style="margin-left: 10px"
                            effect="dark"
                            :content="$t('nodeDetail.third.open')"
                            placement="top-start"
                          >
                            <i
                              @click="open_target(item.raw)"
                              style="cursor: pointer; font-size: 20px"
                              class="iconfont icon-a-yonghuzhinan2"
                            ></i>
                          </el-tooltip>
                        </div> -->

                        <iframe
                          v-if="item.flag"
                          style="min-width: 1000px; height: 500px; width: 100%"
                          :src="item.url"
                          frameborder="0"
                        ></iframe>
                        <div
                          v-else
                          v-html="item.url"
                          style="
                            min-width: 1000px;
                            height: 500px;
                            width: 100%;
                            overflow: auto;
                          "
                        ></div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-dialog>
          </el-table>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
import {
  get_node_histories,
  get_node_use_node_key,
  only_set_property,
} from "@/network/node/index.js";
import axios from "axios";
import PersonalAvatar from "../../personal";
import { download_drawio } from "@/network/fileOperation/index.js";
import HtmlDiff from "htmldiff-js";
import { mapGetters } from "vuex";
import ViewTestStep from "./ViewTestStep.vue";
export default {
  components: {
    PersonalAvatar,
    ViewTestStep,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  inject: ["nodeKey"],
  data() {
    return {
      currentDescription: "",
      descriptionVisible: false,
      isViewerVisible: false,
      currentSrcList: [],
      nodeUuid: "",
      projectId: this.get_pid(),
      history_records_list: [],
      //操作字段映射
      typeMap: {
        Create: this.$t("nodeDetail.activity.history.typeMap.Create"),
        Change: this.$t("nodeDetail.activity.history.typeMap.Change"),
        RelationAdd: this.$t("nodeDetail.activity.history.typeMap.RelationAdd"),
        testPlan: this.$t("nodeDetail.activity.history.typeMap.testPlan"),
        RelationDel: this.$t("nodeDetail.activity.history.typeMap.RelationDel"),
        CreateGanttItem: this.$t("nodeDetail.activity.history.CreateGanttItem"),
        DeleteGanttItem: this.$t("nodeDetail.activity.history.DeleteGanttItem"),
        UpdateGanttItem: this.$t("nodeDetail.activity.history.UpdateGanttItem"),
        MoveGanttItem: this.$t("nodeDetail.activity.history.MoveGanttItem"),
      },
      beforeSrcVisible: false,
      afterSrcVisible: false,
      currentBeforeSrc: "",
      currentAfterSrc: "",
      sprintFinish: false,
      versionFinish: false,
      ThirdlinkVisible: false,
      isLoaded: false,
      activeName: [],
      infiniteCount: 0,
      Thirdlink_data: [],
      Thirdlink_activeName: "0",
      Thirdlink_tab_data: [],
    };
  },
  computed: {
    ...mapGetters(["status"]),
  },
  mounted() {},
  methods: {
    async fetchFileContent(url) {
      const response = await axios.get(url);
      return response.data;
    },
    async get_item() {
      this.Thirdlink_tab_data = [];
      for (let i of this.Thirdlink_data) {
        let res = [];

        for (let j of i.link) {
          if (typeof j === "string") {
            if (this.judge_url(j)) {
              res.push({
                url: await this.fetchFileContent(j),
                flag: false,
                raw: j,
              });
            } else {
              res.push({ url: j, flag: true, raw: j });
            }
          } else {
            res.push(j);
          }
        }
        this.Thirdlink_tab_data.push({ name: i.name, link: res });
      }
    },
    judge_url(url) {
      if (url.includes("//")) {
        let parts = url.split("//")[1];
        let domainPrefix = parts.split("/")[0];
        if (domainPrefix.includes("oss")) {
          return true;
        }
      } else {
        let parts = url.split("//")[0];
        let domainPrefix = parts.split("/")[0];
        if (domainPrefix.includes("oss")) {
          return true;
        }
      }
      return false;
    },
    // 打开弹窗
    openTHIRDLINK(data) {
      this.Thirdlink_data = data;
      this.get_item();
      this.Thirdlink_activeName = "0";
      this.ThirdlinkVisible = true;
    },
    loadinfinite() {
      if (this.history_records_list.length - 3 <= this.infiniteCount) {
        this.infiniteCount = this.history_records_list.length;
      } else {
        this.infiniteCount += 3;
      }
    },
    getType(scene) {
      const map = {
        NodeSync: this.$t("nodeDetail.activity.history.NodeSync"),
        ChangeRequest: this.$t("nodeDetail.activity.history.ChangeRequest"),
      };
      if (!scene) {
        return "";
      } else {
        return map[scene];
      }
    },
    // 恢复字段
    recoverDrawio(row) {
      const nodeKey = this.nodeKey;
      this.$confirm(
        this.$t("nodeDetail.activity.history.recoverMsg"),
        this.$t("file.dialog.tip2"),
        {
          confirmButtonText: this.$t("btn.confirmBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          type: "warning",
        }
      )
        .then(async () => {
          const nodeInfo = await get_node_use_node_key(this.get_pid(), nodeKey);
          const params = {
            projectId: this.get_pid(),
            nodeKey: nodeInfo.key,
            data: {
              isCustom: "",
              fieldId: "",
              value: "",
            },
          };
          if (row.fieldId === "architectureDiagrams") {
            nodeInfo.architectureDiagrams = row.beforeOrigin;
            params.data.isCustom = false;
            params.data.fieldId = "architecture_diagrams";
            params.data.value = row.beforeOrigin;
          } else {
            nodeInfo.content.forEach((item) => {
              if (item.fieldId === row.fieldId) {
                item.value = JSON.stringify(row.beforeOrigin);
                params.data.isCustom = true;
                params.data.fieldId = item.fieldId;
                params.data.value = JSON.stringify(row.beforeOrigin);
              }
            });
          }

          only_set_property(params)
            .then((res) => {
              this.$router.go(0);
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    openDescriptionDialog(html) {
      this.descriptionVisible = true;
      this.currentDescription = html;
    },
    openAfterDesc(before, after) {
      this.currentDescription = HtmlDiff.execute(before, after);
      this.descriptionVisible = true;
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    showImgViewer(srcList) {
      this.currentSrcList = srcList;
      this.isViewerVisible = true;
    },
    openImgDialog(idx, imgSrc) {
      if (idx === "before") {
        this.beforeSrcVisible = true;
        this.currentBeforeSrc = imgSrc;
      } else {
        this.afterSrcVisible = true;
        this.currentAfterSrc = imgSrc;
      }
    },
    getHistory(projectId, nodeUuid) {
      this.projectId = projectId;
      this.nodeUuid = nodeUuid;
      this.getNodeHistory(projectId, nodeUuid);
    },
    async getNodeHistory() {
      this.versionFinish = false;

      const versions = Object.values(
        JSON.parse(sessionStorage.getItem("_version"))
      );
      const baselines = Object.values(
        JSON.parse(sessionStorage.getItem("_baseLine"))
      );
      const allTest = Object.values(
        JSON.parse(sessionStorage.getItem("_testPlan"))
      );
      const allSprints = Object.values(
        JSON.parse(sessionStorage.getItem("_sprint"))
      );

      get_node_histories(this.projectId, this.nodeUuid)
        .then(async (res) => {
          this.history_records_list = [];
          const that = this;
          if (res) {
            res.forEach((item) => {
              const user = that.matchUserInfo(item.author);
              const oneList = {
                children: [],
                scene: item.scene,
              };
              item.changeList.forEach((listItem) => {
                const record = {};
                if (user) {
                  record["avatar"] = user.avatar;
                  record["nickname"] = user.nickname;
                }
                record["accountId"] = item.author;
                record["before"] = listItem.before;
                record["after"] = listItem.after;
                record["createdAt"] = new Date(item.createdAt).Format(
                  "yyyy-MM-dd hh:mm:ss"
                );
                record["itemChName"] = listItem.itemShowName;
                record["type"] = listItem.type;
                oneList.children.push(record);
                if (listItem.fieldType === "SINGLE_USER_SELECT") {
                  //要是支持?.就好了 得加配置算了
                  const beforeUser = that.matchUserInfo(listItem.before);
                  if (beforeUser) {
                    record["before"] = beforeUser.nickname;
                  }
                  const afterUser = that.matchUserInfo(listItem.after);
                  if (afterUser) {
                    record["after"] = afterUser.nickname;
                  }
                } else if (listItem.fieldType === "MULTI_USER_SELECT") {
                  const beforeUserArr = [];
                  const afterUserArr = [];
                  if (listItem.before && listItem.before.length > 0) {
                    listItem.before.forEach((beforeUser) => {
                      beforeUserArr.push(
                        that.matchUserNickname(beforeUser, "")
                      );
                    });
                  }
                  if (listItem.after && listItem.after.length > 0) {
                    listItem.after.forEach((afterUser) => {
                      afterUserArr.push(that.matchUserNickname(afterUser, ""));
                    });
                  }
                  record["before"] = beforeUserArr;
                  record["after"] = afterUserArr;
                } else if (listItem.fieldType === "DATE") {
                  record["before"] = new Date(listItem.before).Format(
                    "yyyy-MM-dd hh:mm:ss"
                  );
                  record["after"] = new Date(listItem.after).Format(
                    "yyyy-MM-dd hh:mm:ss"
                  );
                } else if (listItem.fieldType === "ARRAY") {
                  record["before"] = listItem.before;
                  record["after"] = listItem.after;
                } else if (listItem.fieldType === "STATUS") {
                  record["before"] = this.status[listItem.before]
                    ? this.status[listItem.before].name
                    : "";
                  record["after"] = this.status[listItem.after]
                    ? this.status[listItem.after].name
                    : "";
                } else if (listItem.fieldType === "DRAWIO") {
                  record["beforeSrcList"] = [];
                  record["afterSrcList"] = [];
                  // 添加一下三个字段用于架构图回退
                  record["beforeOrigin"] = [];
                  record["afterOrigin"] = [];
                  record["fieldId"] = listItem.itemName;
                  const reader = function (file) {
                    return new Promise(function (resolve, reject) {
                      const reader = new FileReader();
                      reader.onload = function () {
                        resolve(reader.result);
                      };
                      reader.readAsText(file);
                    });
                  };

                  const asyncFuncs = [];
                  const getImgs = async function (list, type) {
                    for (const item of list) {
                      asyncFuncs.push(
                        download_drawio(item.data).then((res) => {
                          return { res, type };
                        })
                      );
                      // blobToDataURL(i, (baseData)=>{
                      //   record[`${type}SrcList`].push(baseData);
                      // })
                    }
                  };
                  if (listItem.before) {
                    typeof listItem.before == "string"
                      ? (record["beforeOrigin"] = JSON.parse(listItem.before))
                      : (record["beforeOrigin"] = listItem.before);
                    getImgs(
                      typeof listItem.before == "string"
                        ? JSON.parse(listItem.before)
                        : listItem.before,
                      "before"
                    );
                  }
                  if (listItem.after) {
                    typeof listItem.after == "string"
                      ? (record["afterOrigin"] = JSON.parse(listItem.after))
                      : (record["afterOrigin"] = listItem.after);
                    getImgs(
                      typeof listItem.after == "string"
                        ? JSON.parse(listItem.after)
                        : listItem.after,
                      "after"
                    );
                  }
                  Promise.all(asyncFuncs).then((res) => {
                    const afuncs = [];
                    res.forEach((item) => {
                      afuncs.push(
                        reader(item.res).then((res) => {
                          return { res, type: item.type };
                        })
                      );
                    });
                    Promise.all(afuncs).then((res) => {
                      res.forEach((item) => {
                        that.$nextTick(() => {
                          record[`${item.type}SrcList`].push(item.res);
                        });
                      });
                      that.history_records_list.splice();
                    });
                  });

                  // if (listItem.before && listItem.before.length > 0) {
                  //   record["beforeSrc"] = listItem.before[0].data;
                  // }
                  // if (listItem.after && listItem.after.length > 0) {
                  //   record["afterSrc"] = listItem.after[0].data;
                  // }
                } else if (listItem.fieldType === "POSITIVE_INTEGER") {
                  record["before"] = [listItem.before];
                  record["after"] = [listItem.after];
                } else if (listItem.fieldType === "ATTACHMENT") {
                  let beforeString = "";
                  let afterString = "";
                  listItem.before &&
                    listItem.before.forEach(
                      (file) => (beforeString += " " + file.name)
                    );
                  listItem.after &&
                    listItem.after.forEach(
                      (file) => (afterString += " " + file.name)
                    );
                  record["before"] = beforeString;
                  record["after"] = afterString;
                } else if (listItem.itemName === "sprint") {
                  const beforeArr = [];
                  const afterArr = [];
                  allSprints.forEach((sprint) => {
                    if (listItem.before) {
                      listItem.before.forEach((itemBefore) => {
                        if (itemBefore === sprint.sprintId) {
                          beforeArr.push(sprint.name);
                        }
                      });
                    }
                    if (listItem.after) {
                      listItem.after.forEach((itemAfter) => {
                        if (itemAfter === sprint.sprintId) {
                          afterArr.push(sprint.name);
                        }
                      });
                    }
                  });
                  record["before"] = beforeArr;
                  record["after"] = afterArr;
                } else if (listItem.fieldType === "RICH_TEXT") {
                  record["beforeDescription"] = true;
                  record["afterDescription"] = true;
                  record["beforeDescriptionContent"] = listItem.before;
                  record["afterDescriptionContent"] = listItem.after;
                } else if (listItem.itemName === "version") {
                  const beforeArr = [];
                  const afterArr = [];
                  versions.forEach((version) => {
                    if (listItem.before) {
                      listItem.before.forEach((itemBefore) => {
                        if (itemBefore === version.versionId) {
                          beforeArr.push(version.name);
                        }
                      });
                    }
                    if (listItem.after) {
                      listItem.after.forEach((itemAfter) => {
                        if (itemAfter === version.versionId) {
                          afterArr.push(version.name);
                        }
                      });
                    }
                  });
                  record["before"] = beforeArr;
                  record["after"] = afterArr;
                } else if (listItem.itemName === "testPlan") {
                  const beforeArray = [];
                  const afterArray = [];
                  allTest.forEach((test) => {
                    if (listItem.before) {
                      listItem.before.forEach((beforeItem) => {
                        if (beforeItem === test.testPlanId) {
                          beforeArray.push(test.name);
                        }
                      });
                    }
                    if (listItem.after) {
                      listItem.after.forEach((afterItem) => {
                        if (afterItem === test.testPlanId) {
                          afterArray.push(test.name);
                        }
                      });
                    }
                  });
                  record["before"] = beforeArray;
                  record["after"] = afterArray;
                } else if (listItem.itemName === "baseline") {
                  const beforeArray = [];
                  const afterArray = [];
                  baselines.forEach((test) => {
                    if (listItem.before) {
                      listItem.before.forEach((beforeItem) => {
                        if (beforeItem === test.baselineId) {
                          beforeArray.push(test.name);
                        }
                      });
                    }
                    if (listItem.after) {
                      listItem.after.forEach((afterItem) => {
                        if (afterItem === test.baselineId) {
                          afterArray.push(test.name);
                        }
                      });
                    }
                  });
                  record["before"] = beforeArray;
                  record["after"] = afterArray;
                } else if (listItem.type === "CreateGanttItem") {
                  if (listItem.before == "@false") {
                    record["before"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  if (listItem.after == "@false") {
                    record["after"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  record["itemChName"] = this.$t(
                    "nodeDetail.activity.history.ganttName"
                  );
                } else if (listItem.type === "DeleteGanttItem") {
                  if (listItem.before == "@false") {
                    record["before"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  if (listItem.after == "@false") {
                    record["after"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  record["itemChName"] = this.$t(
                    "nodeDetail.activity.history.ganttName"
                  );
                } else if (listItem.type === "MoveGanttItem") {
                  if (listItem.before == "@false") {
                    record["before"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  if (listItem.after == "@false") {
                    record["after"] = this.$t(
                      "nodeDetail.activity.history.remove"
                    );
                  }
                  record["itemChName"] = this.$t(
                    "nodeDetail.activity.history.ganttName"
                  );
                } else if (listItem.type === "UpdateGanttItem") {
                  if (listItem.itemName == "man_hour") {
                    record["before"] =
                      parseFloat(
                        Math.round((listItem.before / 8000) * 10) / 10
                      ) +
                      " " +
                      this.$t("nodeDetail.activity.history.day");
                    record["after"] =
                      parseFloat(
                        Math.round((listItem.after / 8000) * 10) / 10
                      ) +
                      " " +
                      this.$t("nodeDetail.activity.history.day");
                    record["itemChName"] = this.$t(
                      "nodeDetail.activity.history.man_hour"
                    );
                  }
                  if (listItem.itemName == "progress") {
                    record["before"] = listItem.before + " " + "%";
                    record["after"] = listItem.after + " " + "%";
                    record["itemChName"] = this.$t(
                      "nodeDetail.activity.history.progress"
                    );
                  }
                  if (listItem.itemName == "plan_man_hour") {
                    record["before"] =
                      parseFloat(
                        Math.round((listItem.before / 8000) * 10) / 10
                      ) +
                      " " +
                      this.$t("nodeDetail.activity.history.day");
                    record["after"] =
                      parseFloat(
                        Math.round((listItem.after / 8000) * 10) / 10
                      ) +
                      " " +
                      this.$t("nodeDetail.activity.history.day");
                    record["itemChName"] = this.$t(
                      "nodeDetail.activity.history.plan_man_hour"
                    );
                  }
                  if (listItem.itemName == "start_time") {
                    if (listItem.before) {
                      record["before"] = new Date(listItem.before).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      );
                    }
                    if (listItem.after) {
                      record["after"] = new Date(listItem.after).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      );
                    }
                    record["itemChName"] = this.$t(
                      "nodeDetail.activity.history.StartTime"
                    );
                  }
                  if (listItem.itemName == "due_time") {
                    if (listItem.before) {
                      record["before"] = new Date(listItem.before).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      );
                    }
                    if (listItem.after) {
                      record["after"] = new Date(listItem.after).Format(
                        "yyyy-MM-dd hh:mm:ss"
                      );
                    }
                    record["itemChName"] = this.$t(
                      "nodeDetail.activity.history.DueTime"
                    );
                  }
                } else if (
                  listItem.type === "RelationAdd" ||
                  listItem.type === "RelationDel"
                ) {
                  record["itemChName"] = this.$t("menu.node");
                }
                if (listItem.itemName === "thirdLink") {
                  record["itemName"] = "thirdLink";
                }
              });
              that.history_records_list.push(oneList);
            });
          }
          that.versionFinish = true;
          this.history_records_list.forEach((item, index) => {
            this.activeName.push(index + 1);
          });
          that.infiniteCount =
            this.history_records_list.length > 3
              ? 3
              : this.history_records_list.length;
        })
        .catch((e) => {
          this.versionFinish = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 500px !important;
}
</style>
<style lang="scss">
.el-table__body {
  color: rgba(128, 128, 128, 1);
}

.img {
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.nodehistoryrecord {
  .uesr {
    display: flex;
    margin-left: 40px;
    align-items: center;

    p {
      font-size: 14px;
    }
  }

  .el-table {
    border: 1px solid rgba(229, 229, 229, 1);
  }

  .el-table--border {
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
.img-viewer-text {
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #003b73;
}
.check-btn {
  &:link,
  &:visited {
    color: #555;
    font-weight: 700;
  }
  &:hover,
  &:active {
    color: #003b73;
  }
}
.html-template {
  padding-bottom: 50px;
  max-height: 50vh;
  overflow: auto;
  img {
    max-width: 900px;
  }
}
ins {
  opacity: 1;
  background: rgba(42, 130, 228, 0.13);
  text-decoration: none;
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}

del {
  opacity: 1;
  background: rgba(128, 128, 128, 0.3);
  padding-top: 5px; /* Adjust these values as needed */
  padding-bottom: 5px;
  box-decoration-break: clone;
}
</style>

<style>
.el-image-viewer__canvas img {
  background-color: rgb(255, 255, 255);
  padding: 30px;
}
</style>
