<template>
  <div ref="echarts-wrap" class="echarts_component">
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="$t('nodeDetail.relationChart.centerView')"
        name="first"
      >
        <el-alert
          class="tip"
          show-icon
          :closable="false"
          :title="$t('nodeDetail.relationChart.tip')"
          type="info"
          style="margin-bottom: 10px"
        >
        </el-alert>
        <div id="relation_chart" ref="relation_chart"></div>
        <div class="add_relation">
          <el-form
            v-limits-of-authority="'NODE_EDIT'"
            :inline="true"
            class="demo-form-inline"
            size="medium"
          >
            <el-form-item
              :label="$t('nodeDetail.relationChart.addRelatedNode')"
            >
              <el-select
                v-model="value"
                multiple
                filterable
                remote
                reserve-keyword
                collapse-tags
                :placeholder="$t('nodeDetail.relationChart.placeholder')"
                :loading-text="$t('loading.loading2')"
                :remote-method="get_unrelation_node"
                :loading="loading"
                @visible-change="clearData"
                popper-class="select-icon-height-auto-class"
              >
                <el-option
                  v-for="item in options"
                  :key="item.nodeKey"
                  :label="`${item.nodeKey}: ${cut(item.topic)}`"
                  :value="item.nodeKey"
                >
                  <!-- ms8613代码定位 -->
                  <select-icon :item="item"> </select-icon>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="add_relation">{{
                $t("btn.confirmBtn")
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('nodeDetail.relationChart.vmodelView')"
        name="second"
      >
        <vmodel-view
          v-if="activeName === 'second'"
          :isdialog="isdialog"
          :relation_data="nodeRelationGraphList"
        ></vmodel-view>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('nodeDetail.relationChart.wmodelView')"
        name="third"
      >
        <wmodel-view
          v-if="activeName === 'third'"
          :relation_data="nodeRelationGraphList"
        ></wmodel-view>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  get_relation_data,
  get_relation_node,
  add_relation_node,
  cancel_relation_node,
} from "@/network/node/index.js";
import { searchNodesByFuzzyKey } from "@/network/fileOperation/index.js";
import { mapGetters } from "vuex";
import vmson from "@/utils/vmson";
import vmodelView from "./vmodelView.vue";
import wmodelView from "./wmodelView.vue";
import selectIcon from "@/components/selectIcon";

export default {
  components: {
    vmodelView,
    wmodelView,
    selectIcon,
  },
  props: {
    node_key: {
      type: String,
      default: "",
    },
    nodeInfo: {
      type: Object,
    },
    fileTypeId: {
      type: String,
    },
    isdialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options(newValue, oldValue) {
      // oldValue.forEach(item => {
      //   if(this.value.includes(item.nodeKey) && !this.inAllItems(item.nodeKey)){
      //     this.allItems.push({nodeKey: item.nodeKey, fileTypeId: item.fileTypeId, projectId: item.projectId});
      //   }
      // })
      oldValue.forEach((item) => {
        if (!this.inAllOptions(item.nodeKey)) {
          this.allOptions.push(item);
        }
      });
    },
    // value(newValue, oldValue){
    //   console.log(newValue);
    //   if(oldValue.length > newValue.length){
    //     const minus = oldValue.filter(item => !newValue.includes(item));
    //     this.allItems = this.allItems.filter(item => !minus.includes(item));
    //   }else{
    //     this.options.forEach(option => {
    //       if(newValue.includes(option.nodeKey)){
    //         this.allItems.push({nodeKey: option.nodeKey, projectId: option.projectId, fileTypeId: option.fileTypeId})
    //       }
    //     })
    //   }
    // }
  },

  data() {
    return {
      allOptions: [],
      activeName: "first",
      node_relation_data: {},
      categories: [],
      link: [],
      myChart: null,
      color: [
        "rgba(255, 249, 199, 1)",
        "rgba(226, 241, 255, 1)",
        "rgba(218, 255, 221, 1)",
        "rgba(238, 218, 218, 1)",
        "rgba(206, 242, 242, 1)",
        "rgba(245, 203, 179, 1)",
        "rgba(213, 197, 235, 1)",
        "rgba(221, 232, 158, 1)",
        "rgba(166, 237, 208, 1)",
        "rgba(158, 195, 240, 1)",
      ],
      options: [],
      value: [],
      loading: false,
      no_tip: true,
    };
  },
  computed: {
    ...mapGetters(["cancel_auto", "file_type_id"]),
    nodeRelationGraphList() {
      return this.node_relation_data.nodeRelationGraphList;
    },
    relation_data() {
      let temp = this.node_relation_data.nodeRelationGraphList;
      // let temp = { 'category': 2, 'current': false, 'id': "QWE-91", 'name': "rer哒哒哒哒哒哒多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多多", 'nodeKey': "QWE-91" }

      let categoryArr = [];
      this.categories.forEach((ele) => categoryArr.push(ele.name));
      temp.forEach((ele) => {
        ele.id = ele.nodeKey;
        if (ele.current) {
          ele.category = null;
          ele.symbolSize = [100, 75];
          ele.itemStyle = {
            color: "rgba(48, 100, 143, 1)",
          };
        } else {
          categoryArr.forEach((item, idx) => {
            if (ele.category == item) {
              ele.category = idx;
            }
          });
        }
      });
      return temp;
    },
    category_num() {
      let arr = [];
      this.categories.forEach((ele) => {
        arr.push({ name: `${ele.name} ${ele.count}` });
      });
      return arr;
    },
    category_color() {
      let num = this.node_relation_data.numberOfNodesOfEachTypeList.length;
      return this.color.slice(0, num);
    },
  },
  mounted() {
    const chart = this.$refs.relation_chart;
    this.myChart = this.$echarts.init(chart);
    this.get_data();
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
    let _this = this;
    // 双击进入节点详情
    this.myChart.on("dblclick", "series", function (params) {
      _this.toNodeDetailPage(params.data.nodeKey, params.data.projectId);
    });
    // 关掉原生的右击
    this.myChart.off("contextmenu");
    //新加上鼠标右击事件
    this.myChart.on("contextmenu", "series", function (params) {
      let targetNodeKey = params.data.nodeKey;
      const targetProjectId = params.data.projectId;
      if (params.data.category != null) {
        _this.cancel_relation(targetNodeKey, targetProjectId);
      }
    });
  },

  methods: {
    inAllOptions(nodeKey) {
      let flag = false;
      this.allOptions.forEach((option) => {
        if (option.nodeKey === nodeKey) {
          flag = true;
        }
      });
      return flag;
    },
    cut(str) {
      if (str.length > 15) {
        return str.slice(0, 14) + "...";
      } else {
        return str;
      }
    },
    get_data() {
      this.$emit("hideLoading", true);
      get_relation_data(this.get_pid(), this.node_key)
        .then((res) => {
          this.node_relation_data = res;
          this.categories = res.numberOfNodesOfEachTypeList;
          this.link = res.link;
          this.draw_relation();
          this.$emit("hideLoading", false);
        })
        .catch((err) => console.log(err));
    },
    draw_relation() {
      const option = {
        color: this.category_color,
        title: {
          subtext: "",
          textStyle: {
            fontSize: 20,
          },
          subtextStyle: {
            fontSize: 12,
          },
        },
        // 提示框的配置
        tooltip: {
          formatter: function (x) {
            return x.data.name;
          },
        },
        legend: [
          {
            orient: "vertical",
            y: "top",
            left: "74%",
            borderWidth: 2.5,
            padding: [12, 8],
            borderRadius: 3,
            itemGap: 16,
            data: this.category_num.map(function (a) {
              return a.name;
            }),
          },
        ],
        series: [
          {
            type: "graph", // 类型:关系图
            layout: "force", //图的布局，类型为力导图
            symbolSize: [90, 65], // 调整节点的大小[宽，高]
            roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            categories: this.category_num,
            edgeSymbol: ["circle", "arrow"],
            edgeSymbolSize: 3,
            animation: false,
            label: {
              // 节点文本配置
              color: "rgba(0, 0, 0, 1)",
              show: true,
              position: "inside",
              width: 60,
              height: 10,
              overflow: "break",
              formatter: function (param) {
                let name = param.name;
                if (name.length <= 14) {
                  return name;
                } else {
                  name = name.slice(0, 14) + "...";
                  return name;
                }
              },
            },
            force: {
              repulsion: 1500,
              edgeLength: [40, 80],
              layoutAnimation: false,
            },
            draggable: false,
            lineStyle: {
              normal: {
                width: 2,
                color: "#a4e0df",
              },
            },
            // 数据
            data: this.relation_data,
            links: this.link,
          },
        ],
      };
      //去除默认的鼠标事件
      this.$refs["echarts-wrap"].oncontextmenu = function () {
        return false;
      };
      this.myChart.setOption(option);
    },
    get_unrelation_node(query) {
      if (query !== "") {
        this.loading = true;
        let related_nodes = this.node_relation_data.nodeRelationGraphList.map(
          (ele) => {
            return ele.nodeKey;
          }
        );
        searchNodesByFuzzyKey(query)
          .then((res) => {
            this.options = res.filter((ele) => {
              if (related_nodes.indexOf(ele.nodeKey) == -1) {
                return ele;
              }
            });
            this.loading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.options = [];
      }
    },
    add_relation() {
      this.$emit("hideLoading", true);
      let arr = [];
      this.options.forEach((option) => {
        if (!this.inAllOptions(option.nodeKey)) {
          this.allOptions.push(option);
        }
      });
      this.allOptions.forEach((item) => {
        if (this.value.indexOf(item.nodeKey) != -1) {
          arr.push({
            sourceFileTypeId: this.nodeInfo
              ? this.nodeInfo.fileTypeId
              : this.fileTypeId,
            targetFileTypeId: item.fileTypeId,
            sourceNodeKey: this.node_key,
            targetNodeKey: item.nodeKey,
            sourceProjectId: this.get_pid(),
            targetProjectId: item.projectId,
          });
        }
      });
      add_relation_node(this.get_pid(), this.node_key, arr)
        .then(() => {
          this.value = [];
          this.get_data();
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
        })
        .catch((err) => {
          this.$emit("hideLoading", false);
          console.log(err);
        });
    },
    cancel_relation(targetNodeKey, targetProjectId) {
      if (this.cancel_auto) {
        this.$emit("hideLoading", true);
        this.delete_ralation(targetNodeKey, targetProjectId);
      } else {
        this.$msgbox({
          title: this.$t("baseline.topBar.beginBaseline.title"),
          dangerouslyUseHTMLString: true,
          message:
            `
          <div style="margin-bottom:10px">` +
            this.$t("nodeDetail.relationChart.deleteRelation.info") +
            `</div>
          <div style="display:flex;align-items: center;">
          <label class="labelOne" style="display:flex;">
              <input type="checkbox" id="cancel_tip" name="tip" :value="$t('nodeDetail.relationChart.deleteRelation.tip')"/>
          </label>
          <span style="font-size:12px">` +
            this.$t("nodeDetail.relationChart.deleteRelation.tip") +
            `</span>
          </div>
          `,
          showCancelButton: true,
          confirmButtonText: this.$t("btn.confirmBtn"),
          cancelButtonText: this.$t("btn.cancelBtn"),
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = this.$t(
                "nodeDetail.relationChart.text"
              );
              // 勾选了不再提示
              let cancel = document.querySelector("#cancel_tip").checked;
              if (cancel) {
                this.$store.commit("SET_CANCEL_AUTO", true);
              }
              this.delete_ralation(targetNodeKey, targetProjectId);
              done();
              instance.confirmButtonLoading = false;
            } else {
              done();
            }
          },
        }).then(() => {
          // 节点关联关系变化事件
          vmson.$emit("node_relation_change");
          this.get_data();
        });
      }
    },
    delete_ralation(targetNodeKey, targetProjectId) {
      let data = {
        sourceNodeKey: this.node_key,
        targetNodeKey,
        sourceProjectId: this.get_pid(),
        targetProjectId,
      };
      cancel_relation_node(this.get_pid(), this.node_key, data)
        .then(() => this.get_data())
        .catch((err) => console.log(err));
    },
    clearData() {
      if (this.value.length == 0) this.options = [];
    },
    toNodeDetailPage(node_key, targetProjectId) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: targetProjectId,
          nodeKey: node_key,
        },
      });
      //结合open打开新的页面
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.select-icon-height-auto-class {
  .el-select-dropdown__item {
    display: flex;
  }
  .el-select-dropdown__item.selected::after {
    right: 10px !important;
  }
}
.echarts_component {
  padding: 0 20px;

  #relation_chart {
    height: 60vh;

    div {
      height: 100%;
    }
  }

  .add_relation {
    text-align: left;

    ::v-deep .el-select {
      .el-tag:first-child {
        display: flex;
        align-items: center;

        .el-select__tags-text {
          display: inline-block;
          width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
